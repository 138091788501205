import axios from 'axios';
import { ref } from 'vue';
import { CartModel } from '~/models/cart';
import { callFunc } from '~/realm/data/call';
import { dataFetchSingle, dataFetchSingleWhere } from '~/realm/data/get';
import { dataFetchList } from '~/realm/data/list'

const formState = {
  shippingAddress: {
    firstName: '',
    lastName: '',
    houseNumber: '',
    phone: '',
    email: '',
    userId: '',
    addressType: '',
    country: 'MY',
    address: '',
    postcode: '',
    city: '',
    state: ''
  }
}

export async function mapBoxLocation(acl) {
  if (!acl.cart._id) {
    await acl.loadCart()
  }
  if(!acl.cart.shippingAddress?.state) {
    getLocation(acl)
  }
}

async function getLocation(acl) {
  console.log('getlocation!')
    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };
    async function success(pos) {
        var crd = pos.coords;
        const api = 'sk.eyJ1IjoiYmxhemV0IiwiYSI6ImNsMDYzOXhpczBxcHkzY250OXE1dGJrMTYifQ.l7Y8tXMY4a9M5ztcaWP9ew'
        try {
          await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${crd.longitude},${crd.latitude}.json?types=place&access_token=${api}`).then(async (res) => {
            // The Respon of Mapbox is not stable, the property of short_code sometime appear in another place in the Object
            formState.shippingAddress.state = res.data.features[0].context[0].short_code
            acl.cart.shippingAddress = formState.shippingAddress
            await callFunc('cartShippingAddress', acl.cartSessionToken, {
              address: acl.cart.shippingAddress,
            })
          })
        } catch (error) {
          console.error(error)
        }

    }
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.getCurrentPosition(success, error, options)
}